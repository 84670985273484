<template>
  <div class="add-container">
    <div class="form-box">
      <el-form
        v-for="(item, index) in formList"
        label-position="left"
        label-width="120px"
        class="form-container"
        :key="index"
      >
        <el-form-item
          label="推广账号"
          style="margin-bottom: 14px"
          class="necessary"
        >
          <el-popover
            :disabled="formList[index].options.length === 0"
            placement="top-start"
            width="300"
            trigger="hover"
          >
            <el-tag
              :key="tag.id"
              v-for="tag in formList[index].options"
              :disable-transitions="false"
              style="margin: 6px"
              closable
              @close="handleRemoveTag(tag.id, index)"
            >
              {{ tag.advertiserName }}
            </el-tag>
            <el-select
              :ref="'AccountSlecter' + index"
              v-model="item.params.channelIds"
              multiple
              placeholder="请选择"
              collapse-tags
              @remove-tag="handleRemoveTag(tag, index)"
              @focus="handleShowAccount(index)"
              slot="reference"
              class="account-selector"
            >
              <el-option
                v-for="item in formList[index].options"
                :key="item.id"
                :label="item.advertiserName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-popover>
        </el-form-item>
        <!-- 转化名称 -->
        <el-form-item
          label="转化名称"
          style="margin-bottom: 14px"
          class="necessary"
        >
          <el-input
            type="text"
            placeholder="请输入内容"
            v-model="item.params.name"
            maxlength="25"
            show-word-limit
            style="width: 216px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="转化来源：" class="necessary">
          <el-radio-group v-model="item.params.convertSourceType" size="medium">
            <el-radio-button label="AD_CONVERT_SOURCE_TYPE_APP_DOWNLOAD"
              >app下载</el-radio-button
            >
            <el-radio-button label="AD_CONVERT_SOURCE_TYPE_H5_API"
              >落地页API</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item
          label="转化类型"
          style="margin-bottom: 14px"
          class="necessary"
        >
          <el-radio-group v-model="item.params.convertType" size="medium">
            <el-radio-button label="AD_CONVERT_TYPE_PAY "
              >应用下载</el-radio-button
            >
            <el-radio-button label="AD_CONVERT_TYPE_PAY"
              >激活且付费</el-radio-button
            >
          </el-radio-group>
        </el-form-item> -->

        <el-form-item
          label="应用类型："
          :class="{
            necessary:
              item.params.convertSourceType ===
              'AD_CONVERT_SOURCE_TYPE_APP_DOWNLOAD',
          }"
        >
          <el-radio-group v-model="item.params.app_type" size="medium">
            <el-radio-button label="APP_ANDROID">安卓</el-radio-button>
            <el-radio-button label="APP_IOS">苹果</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="应用中文名"
          style="margin-bottom: 14px"
          :class="{
            necessary:
              item.params.convertSourceType ===
              'AD_CONVERT_SOURCE_TYPE_APP_DOWNLOAD',
          }"
        >
          <el-input
            type="text"
            placeholder="下载app类型时必填"
            v-model="item.params.appName"
            style="width: 216px"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="包名"
          style="margin-bottom: 14px"
          :class="{
            necessary:
              item.params.convertSourceType ===
              'AD_CONVERT_SOURCE_TYPE_APP_DOWNLOAD',
          }"
        >
          <el-input
            type="text"
            placeholder="需与下载链接指向的应用一致"
            v-model="item.params.package_name"
            style="width: 216px"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="点击监测链接"
          :class="{
            necessary:
              item.params.convertSourceType ===
              'AD_CONVERT_SOURCE_TYPE_APP_DOWNLOAD',
          }"
          style="margin-bottom: 14px"
        >
          <el-input
            type="textarea"
            placeholder="请输入下载app类型时必填"
            v-model="item.params.action_track_url"
            style="width: 216px"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="下载/落地页链接"
          style="margin-bottom: 14px"
          class="necessary"
        >
          <el-input
            type="textarea"
            placeholder="请输入下载/落地页链接"
            v-model="item.params.url"
            style="width: 216px"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <!-- 继续添加 -->
      <el-form
        v-if="!editParams"
        label-position="left"
        label-width="120px"
        class="form-container add-more"
      >
        <i class="el-icon-plus class add-icon" @click="handleAddForm"></i>
      </el-form>

      <!-- 账号选择器 -->
      <select-account
        @cancel="showAccount = false"
        @confirm="handleSelected"
        :showAccount="showAccount"
        :seleted="formList[currentForm].options"
      />
    </div>
  </div>
</template>

<script>
import selectAccount from "../components/selectAccount";
export default {
  props: {
    editParams: Object,
  },
  data() {
    return {
      params: {},
      showAccount: false,
      seletedItem: [],
      formList: [
        {
          params: {
            convertType: "AD_CONVERT_TYPE_PAY",
            app_type: "APP_ANDROID",
            convertSourceType: "AD_CONVERT_SOURCE_TYPE_APP_DOWNLOAD",
          },
          options: [],
        },
      ],
      currentForm: 0,
    };
  },
  components: {
    selectAccount,
  },
  methods: {
    handleAddForm() {
      this.formList.push({
        params: { ...this.formList[this.formList.length - 1].params },
        options: [...this.formList[this.formList.length - 1].options],
      });
    },
    handleRemoveTag(item, index) {
      this.formList[this.currentForm].options.forEach((value, index) => {
        if (value.id === item) {
          this.formList[this.currentForm].options.splice(index, 1);
          this.formList[this.currentForm].params.channelIds.splice(index, 1);
          return;
        }
      });
    },
    handleSelected(item) {
      this.formList[this.currentForm].options = [];
      item.forEach((value) => {
        this.formList[this.currentForm].options.push(
          JSON.parse(JSON.stringify(value))
        );
      });
      this.formList[this.currentForm].params.channelIds = item.map((value) => {
        return value.id;
      });
      this.showAccount = false;
    },
    handleShowAccount(index) {
      this.currentForm = index;
      this.$refs["AccountSlecter" + index][0].blur();
      this.showAccount = true;
    },
  },
  created() {
    if (this.editParams) {
      let tmpChannel = [];
      this.editParams.joinChannelList.forEach((item) => {
        tmpChannel.push(item.id);
      });
      this.$set(this.formList[0], "params", {
        ...this.editParams,
        channelIds: tmpChannel,
      });
      this.$set(this.formList[0], "options", this.editParams.joinChannelList);
      // this.formList[0] = {
      //   params: { ...this.editParams, channelIds: tmpChannel },
      //   options: this.editParams.joinChannelList,
      // };
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .necessary > .el-form-item__label {
  position: relative;
}
/deep/ .necessary > .el-form-item__label::before {
  position: absolute;
  top: 0;
  left: -8px;
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
/deep/ .account-selector .el-icon-close {
  display: none !important;
}
.add-container {
  width: 1300px;
  margin: auto;
}
.form-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.form-container {
  width: 400px;
  height: 520px;
  border: 1px solid #fbfbfb;
  padding: 20px;
  background-color: #fbfbfb;
  border-radius: 20px;
  margin-right: 20px;
  margin-top: 10px;
}
.add-more {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  i {
    cursor: pointer;
  }
}
</style>