<template>
  <div class="track-container">
    <div class="track-platform flex-row-start-center">
      <el-button
        type="primary"
        class="margin-right-ten"
        @click="handlePageChange(1)"
        >刷新</el-button
      >
      <!-- <el-input
        v-model="advertiserName"
        placeholder="请输入账户名"
        style="width: 150px"
        class="margin-right-ten"
        clearable
      ></el-input> -->
      <el-select
        v-model="advertiserName"
        filterable
        remote
        clearable
        style="width: 150px"
        reserve-keyword
        placeholder="账户名"
        @change="handlePageChange(1)"
        :remote-method="getAccount"
        class="margin-right-ten"
      >
        <el-option
          v-for="item in accountList"
          :key="item.id"
          :label="item.advertiserName"
          :value="item.advertiserName"
        >
        </el-option>
      </el-select>
      <el-button type="primary" class="margin-right-ten" @click="handleAddTrack"
        >创建转化追踪</el-button
      >
      <el-input
            v-model="postLink"
            style="width: 300px;margin-left:auto"
            placeholder="联调url"
            clearable
            @clear="postLink = null"
          >
            <el-button
              v-loading="postLoading"
              slot="append"
              :disabled="!postLink"
              @click="handleLink"
              >联调</el-button
            ></el-input
          >
    </div>
    <div class="main-container">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="height: 710px; overflow-y: auto"
        @selection-change="handleSelectionChange"
        v-loading="tableLoading"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="转化ID" prop="id" width="80">
          <!-- <template slot-scope="scope">{{ scope.row.date }}</template> -->
        </el-table-column>
        <el-table-column prop="name" label="转化名称" width="120">
        </el-table-column>
        <el-table-column prop="appName" label="应用中文名"> </el-table-column>
        <el-table-column prop="address" label="转化状态"> </el-table-column>
        <el-table-column prop="convertSourceType" label="转化来源" width="100">
          <template slot-scope="scope">
            {{
              scope.row.convertSourceType.includes("H5")
                ? "app下载"
                : "落地页api"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="convertType" label="转化类型" width="80">
          <template slot-scope="scope"> {{ "付费" }} </template>
        </el-table-column>
        <el-table-column
          prop="actionTrackUrl"
          label="点击测试链接"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="appType" label="应用类型" width="84">
          <template slot-scope="scope">
            <template v-if="scope.row.appType">
              <el-tag v-if="scope.row.appType.includes('ANDROID')"
                >android</el-tag
              >
              <el-tag v-if="scope.row.appType.includes('IOS')" type="warning"
                >ios</el-tag
              >
            </template>
            <!-- {{
              scope.row.appType
                ? scope.row.appType.includes("ANDROID")
                  ? "android"
                  : "ios"
                : ""
            }} -->
          </template>
        </el-table-column>
        <el-table-column prop="deepExternalAction" label="深度转化目标">
        </el-table-column>
        <el-table-column prop="packageName" width="160" label="包名">
        </el-table-column>
        <el-table-column prop="url" label="链接" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" width="370">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleShowJoin(scope.row)"
              >关联详情</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="handleUpload(scope.row)"
              >上传</el-button
            >
            <el-button size="mini" type="warning" @click="handleEdit(scope.row)"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        :page-size="pageSize"
        @current-change="handlePageChange"
        :current-page="page"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <!-- 关联数据弹框 -->
      <el-drawer
        size="700"
        :title="'关联详情：' + currentCount"
        :visible.sync="showJoin"
      >
        <el-table :data="JoinData">
          <el-table-column
            property="id"
            label="关联id"
            width="150"
          ></el-table-column>
          <el-table-column
            property="uploadConvertId"
            label="转化目标id"
            width="160"
          ></el-table-column>
          <el-table-column
            property="advertiserName"
            width="140"
            label="账户名"
          ></el-table-column>
          <el-table-column property="status" width="100" label="状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 1">待上传</el-tag>
              <el-tag v-if="scope.row.status == 2" type="success"
                >上传成功</el-tag
              >
              <el-tag v-if="scope.row.status == 3" type="danger"
                >上传失败</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            property="failReason"
            label="失败原因"
            width="250"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </el-drawer>
      <!-- 添加编辑转换追踪 -->
      <el-dialog
        :title="editType === 1 ? '创建转化追踪' : '修改转化追踪'"
        fullscreen
        :visible.sync="showTrack"
        center
      >
        <add-change
          :editParams="editParams"
          v-if="showTrack"
          ref="addChangeRef"
        />
        <span slot="footer" class="dialog-footer">
          <el-button @click="showTrack = false">取 消</el-button>
          <el-button type="warning" @click="handleSave(1)">保存本地</el-button>
          <el-button type="primary" @click="handleSave(2)"
            >保存并上传</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getConvertList,
  addConvert,
  updateConvert,
  deleteConvert,
  uploadConvert,
  getOeRemark,
  putConvertJoint
} from '@/api/toutiao.js'
import addChange from './addChange.vue'
export default {
  data () {
    return {
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      advertiserName: null,
      tableLoading: false,
      JoinData: [],
      showJoin: false,
      showTrack: false,
      editParams: {},
      postLoading: false,
      editType: null,
      currentCount: null,
      accountList: [],
      postLink: null
    }
  },
  components: {
    addChange
  },
  created () {
    this.handlePageChange()
    this.getAccount()
  },
  watch: {},
  methods: {
    getAccount (name) {
      getOeRemark({
        page: 1,
        pageSize: 20,
        name: name
      }).then((res) => {
        this.accountList = res.list
      })
    },
    handleUpload (item) {
      uploadConvert(item.id).then((res) => {
        this.$message.success('上传成功')
      })
    },
    handleDelete (item) {
      this.$confirm('确定要删除此条转化吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteConvert(item.id).then((res) => {
          this.$message.success('删除成功')
          this.handlePageChange(this.page)
        })
      })
    },
    handleEdit (item) {
      this.editType = 2
      this.editParams = {
        ...item,
        package_name: item.name,
        action_track_url: item.actionTrackUrl,
        app_type: item.appType
      }
      this.showTrack = true
    },
    // 保存转化追踪
    async handleSave (type) {
      const formList = this.$refs.addChangeRef._data.formList
      // 必传项判定
      let continueFlag = true
      formList.forEach((item) => {
        if (!item.params.channelIds.length) {
          continueFlag = false
          return this.$message.error('推广账号不能为空')
        }
        if (!item.params.name) {
          continueFlag = false
          return this.$message.error('转换名称不能为空')
        }
        if (!item.params.convertSourceType) {
          continueFlag = false
          return this.$message.error('请选择转化来源')
        }
        if (item.params.convertSourceType != 'AD_CONVERT_SOURCE_TYPE_H5_API') {
          if (!item.params.app_type) {
            continueFlag = false
            return this.$message.error('请选择应用类型')
          }
          if (!item.params.appName) {
            continueFlag = false
            return this.$message.error('请输入应用中文名')
          }
          if (!item.params.package_name) {
            continueFlag = false
            return this.$message.error('请输入包名')
          }
          if (!item.params.action_track_url) {
            continueFlag = false
            return this.$message.error('请输入点击监测链接')
          }
        }
        if (!item.params.url) {
          continueFlag = false
          return this.$message.error('请输入下载/落地页链接')
        }
      })
      if (!continueFlag) {
        return
      }
      //  调用新增
      if (this.editType === 1) {
        for (let i = 0; i < formList.length; i++) {
          await addConvert({
            convert: {
              name: formList[i].params.name,
              appName: formList[i].params.appName,
              convertSourceType: formList[i].params.convertSourceType,
              appType: formList[i].params.app_type,
              url: formList[i].params.url,
              actionTrackUrl: formList[i].params.action_track_url,
              packageName: formList[i].params.package_name,
              convertType: formList[i].params.convertType
            },
            channelIds: formList[i].params.channelIds,
            type: type
          }).then((res) => {})
          if (i === formList.length - 1) {
            this.$message.success('新增成功')
            this.showTrack = false
            this.handlePageChange(this.page)
          }
        }
      } else {
        await updateConvert(this.editParams.id, {
          convert: {
            name: formList[0].params.name,
            appName: formList[0].params.appName,
            convertSourceType: formList[0].params.convertSourceType,
            appType: formList[0].params.app_type,
            url: formList[0].params.url,
            actionTrackUrl: formList[0].params.action_track_url,
            packageName: formList[0].params.package_name,
            convertType: formList[0].params.convertType
          },
          channelIds: formList[0].params.channelIds,
          type: type
        }).then((res) => {
          this.$message.success('修改成功')
          this.showTrack = false
          this.handlePageChange(this.page)
        })
      }
    },
    handleAddTrack () {
      this.editType = 1
      this.editParams = null
      this.showTrack = true
    },
    handleLink () {
      if (!this.postLink) return
      this.postLoading = true
      putConvertJoint(this.postLink).then(() => {
        this.$message.success('成功!')
      }).finally(() => {
        this.postLoading = false
      })
    },
    handleSelectionChange () {},
    handlePageChange (page) {
      this.tableLoading = true
      this.page = page
      getConvertList({
        page: this.page,
        pageSize: this.pageSize,
        advertiserName: this.advertiserName
      })
        .then((res) => {
          this.tableData = res.list
          this.total = res.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleShowJoin (data) {
      this.currentCount = data.name
      this.JoinData = data.joinChannelList
      this.showJoin = true
    }
  }
}
</script>
<style lang='scss' scoped>
.track-container {
  .track-platform {
    margin-bottom: 20px;
  }
}
/deep/ .el-button--mini {
  padding: 7px 15px !important;
}
</style>
